import React from "react";
import { ReactComponent as IconFacebook } from "./assets/icons/facebook.svg";
import { ReactComponent as IconInstagram } from "./assets/icons/instagram.svg";
import icon from "./assets/logo/icon.png";

import "./App.css";

class App extends React.Component {
  render = () => {
    return (
      <div className="card">
        <div className="header">
          <div className="logo">
            {/* <a href=".">FIESTA EXPERIENCE</a> */}
            <a
              href="https://www.facebook.com/lafiestagroup?mibextid=LQQJ4d"
              title="Facebook"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={icon} alt="Logo" height={80} />
            </a>
          </div>
          <div className="social">
            <a
              href="https://www.facebook.com/lafiestagroup?mibextid=LQQJ4d"
              title="Facebook"
              target="_blank"
              rel="noopener noreferrer"
            >
              <IconFacebook className="icon" />
            </a>
            <a
              href="https://instagram.com/fiesta_experience"
              title="Instagram"
              target="_blank"
              rel="noopener noreferrer"
            >
              <IconInstagram className="icon" />
            </a>
          </div>
        </div>
        <div className="content">
          <div className="title-holder">
            <h1>Get ready for the change.</h1>
            <p>
              Website coming soon. Please check back to know more. Shoot us an
              email if you're curious.
            </p>
          </div>
          <a href="mailto:contact@fiestaexperience.tn">
            <div className="cta">Send us an email</div>
          </a>
        </div>
        <div className="footer">
          <span>
            made by{" "}
            <a
              className="underlined"
              href="https://fiestaexperience.tn"
              target="_blank"
              rel="noopener noreferrer"
            >
              Fiesta Experience
            </a>
          </span>
        </div>
      </div>
    );
  };
}

export default App;
